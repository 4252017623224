<template>
  <div>
    <!-- Start Navbar Area -->
    <header id="header">
      <div class="crake-mobile-nav">
        <div class="logo">
          <img :src="`${publicPath}assets/img/azkari-icon.png`" alt="" style="width: 32px">
        </div>
      </div>
      <div class="crake-nav">
        <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" href="/">
              <img :src="`${publicPath}assets/img/azkari-icon.png`" alt="logo" style="width: 64px">
            </a>
            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul class="navbar-nav nav ml-auto">
                <li class="nav-item">
                  <router-link tag="a" class="nav-link" active-class="active" to="/" exact>الرئيسية</router-link>
                </li>
                <li class="nav-item">
                  <router-link tag="a" class="nav-link" active-class="active" to="/mood" exact>كيف تشعر؟</router-link>
                </li>
                <li class="nav-item">
                  <router-link tag="a" class="nav-link" active-class="active" to="/zikir">أذكار المسلم</router-link>
                </li>
                <li class="nav-item">
                  <a href="mailto:athkariii01@gmail.com" class="nav-link">اتصل بنا</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <!-- End Navbar Area -->
    <!-- Start Sidebar Modal -->
    <div class="sidebar-modal">
      <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <i class="icofont-close"></i>
                </span>
              </button>
              <h2 class="modal-title" id="myModalLabel2">
                <a href="/">
                  <img :src="`${publicPath}assets/img/azkari-icon.png`" alt="logo">
                </a>
              </h2>
            </div>
          </div>
          <!-- modal-content -->
        </div>
        <!-- modal-dialog -->
      </div>
      <!-- modal -->
    </div>
    <!-- End Sidebar Modal -->
    <!-- End Search Box -->
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
}
</script>

<style scoped>

</style>
