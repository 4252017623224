import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/Home";
import Mood from "@/components/Mood";
import Zikir from "@/components/Zikir";
import ZikirDetail from "@/components/ZikirDetail";

Vue.use(VueRouter)

export const router = new VueRouter({
    routes: [
        {path: "/", name: "home", component: Home},
        {path: "/mood", name: "mood", component: Mood},
        {path: "/zikir", name: "zikir", component: Zikir},
        {path: "/zikir/:id", name: "zikir-detail", component: ZikirDetail}
    ],
    mode: "hash",
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
