<template>
  <div>
    <!-- Start Footer Area -->
    <footer class="footer-area ptb-100 pb-0 bg-image">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <a href="/" class="logo">
                <img :src="`${publicPath}assets/img/azkari-icon.png`" alt="logo2">
              </a>
              <p> التطبيق الاسلامي الاسهل و الجامع للكثير من الميزات التي يحتاجها المسلم في يومه </p>
              <ul class="social-list">
                <li>
                  <a href="https://goo.gl/d0ojxj">
                    <i class="icofont-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://goo.gl/XKvdvm">
                    <i class="icofont-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://goo.gl/gQbRDi">
                    <i class="icofont-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget pl-5">
              <h3>روابط الموقع</h3>
              <ul class="list">
                <li>
                  <router-link tag="a" class="nav-link" active-class="active" to="/" exact>الرئيسية</router-link>
                </li>
                <li>
                  <router-link tag="a" class="nav-link" active-class="active" to="/mood" exact>كيف تشعر؟</router-link>
                </li>
                <li>
                  <router-link tag="a" class="nav-link" active-class="active" to="/zikir">أذكار المسلم</router-link>
                </li>
                <li>
                  <a href="mailto:athkariii01@gmail.com" class="nav-link">اتصل بنا</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget"></div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget"></div>
          </div>
        </div>
      </div>
      <div class="copyright-area">
        <div class="container">
          <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12 align-middle text-justify">
              <p>© MBH 2019 All right reserved. Designed and Developed by <b>MBH BİLİŞİM TEK. E-TİC. DAN. REK.
                LTD. ŞTİ. </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer Area -->
    <!-- Back Top top -->
    <div class="back-to-top">الاعلى</div>
    <!-- End Back Top top -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
}
</script>

<style scoped>

</style>
