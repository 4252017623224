import Vue from 'vue'
import App from './App.vue'
import {router} from "@/router";


Vue.config.productionTip = false

window.$ = window.jQuery = require('jquery');

let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
