<template>
  <div>
    <!-- Start Page Title Area -->
    <section class="page-title-banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>اذكار المسلم</h2>
          </div>
        </div>
      </div>

      <div class="shape1"><img :src="`${publicPath}assets/img/shape1.png`" alt="img"></div>
      <div class="shape2"><img :src="`${publicPath}assets/img/shape2.png`" alt="img"></div>
      <div class="shape3"><img :src="`${publicPath}assets/img/shape3.png`" alt="img"></div>
      <div class="shape6"><img :src="`${publicPath}assets/img/shape6.png`" alt="img"></div>
      <div class="shape8 rotateme"><img :src="`${publicPath}assets/img/shape8.svg`" alt="shape"></div>
      <div class="shape9"><img :src="`${publicPath}assets/img/shape9.svg`" alt="shape"></div>
    </section>
    <!-- End Page Title Area -->

    <!-- Start Blog Area -->
    <section class="blog-area blog-section ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="sidebar-area">

              <div class="widget widget_post_categories">
                <h3 class="widget-title">الأقسام</h3>
                <div class="bar"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 mb-3" style="display: grid;" v-for="(value, name) in zikirs" :key="name">
            <router-link tag="a" :to="{ name: 'zikir-detail', params: { id: value._id, title: value.zikirlr_title} }">
              <button class="btn btn-success text-center btn-block">{{value["zikirlr_title"]}}</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- End Blog Area -->
  </div>
</template>

<script>
export default {
  name: "Zikir",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      zikirs: [],
    };
  },
  methods: {
    getZikirs() {
      const self = this;
      window.$.ajaxSetup({
        headers: {
          'location': 'web'
        }
      });
      window.$.getJSON('https://api.athkariapp.com/api/v1/zikirs', function (data) {
        self.zikirs = data;
      });
    },
  },
  mounted() {
    this.getZikirs()
  }
}
</script>

<style scoped>

</style>
