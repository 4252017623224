<template>
  <div>
    <!-- Start Page Title Area -->
    <section class="page-title-banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>الاذكار مرتبة حسب الشعور</h2>
          </div>
        </div>
      </div>

      <div class="shape1"><img :src="`${publicPath}assets/img/shape1.png`" alt="img"></div>
      <div class="shape2"><img :src="`${publicPath}assets/img/shape2.png`" alt="img"></div>
      <div class="shape3"><img :src="`${publicPath}assets/img/shape3.png`" alt="img"></div>
      <div class="shape6"><img :src="`${publicPath}assets/img/shape6.png`" alt="img"></div>
      <div class="shape8 rotateme"><img :src="`${publicPath}assets/img/shape8.svg`" alt="shape"></div>
      <div class="shape9"><img :src="`${publicPath}assets/img/shape9.svg`" alt="shape"></div>
    </section>
    <!-- End Page Title Area -->

    <!-- Start Blog Area -->
    <section class="blog-area blog-section ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-12 d-block d-lg-none">
            <div class="sidebar-area mb-3">
              <div class="widget widget_post_categories">
                <h3 class="widget-title">كيف تشعر؟</h3>
                <div class="bar"></div>
                <select class="form-control mobile-moods" @change="printMoodsMobile">
                  <option v-for="(value, name) in moods" :key="name" :value="name"
                          :style='`background-position: 15px center; background-size: 32px; background-repeat: no-repeat; background-image: url("${value.symbol}")`'>
                    {{ value.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-12">
            <div class="row moodMessages">
              <div class="col-lg-12" v-for="(value, name) in moodMessages" :key="name">
                <div class="single-blog-post">
                  <div class="blog-post-content text-center">
                    <p>{{ value.content }}</p>
                  </div>
                  <button
                      :class="value.count == 0 ? 'btn btn-danger text-center btn-block' : 'btn btn-success text-center btn-block'"
                      @click="decreaseNumber(name)">{{ value["count"] }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 d-none d-lg-block">
            <div class="sidebar-area">

              <div class="widget widget_post_categories">
                <h3 class="widget-title">كيف تشعر؟</h3>
                <div class="bar"></div>
                <ul class="moods">
                  <li v-for="(value, name) in moods" :key="name">
                    <a @click='printMoods(name)' :class="name == 0 ? `mood-item mood-${name} active-mood` : `mood-item mood-${name}`"><img :src='value.symbol' width='32'>{{ value.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Blog Area -->
  </div>
</template>

<script>
export default {
  name: "Mood",
  totalVuePackages: "",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moods: [],
      moodMessages: [],
    };
  },
  methods: {
    decreaseNumber(counter) {
      const self = this;
      if (self.moodMessages[counter]["count"] > 0) {
        self.moodMessages[counter]["count"] = self.moodMessages[counter]["count"] - 1;
      } else {
        self.moodMessages[counter]["count"] = 0
      }
    },
    printMoods(moodIndex) {
      const self = this;
      self.moodMessages = []
      window.$('.mobile-moods').val(moodIndex);
      window.$(".mobile-moods").css({
        "background-image": "url(" + self.moods[moodIndex].symbol + ")",
        "background-position": "15px center",
        "background-size": "32px",
        "background-repeat": "no-repeat"
      });
      window.$(".mood-item").removeClass('active-mood');
      window.$(".mood-" + moodIndex).addClass('active-mood');
      const data = self.moods[moodIndex].athkars;
      self.moodMessages = data
    },
    printMoodsMobile() {
      const self = this;
      self.moodMessages = []
      const moodIndex = window.$(".mobile-moods option:selected").val();
      window.$(".mobile-moods").css({
        "background-image": "url(" + self.moods[moodIndex].symbol + ")",
        "background-position": "15px center",
        "background-size": "32px",
        "background-repeat": "no-repeat"
      });
      window.$(".mood-item").removeClass('active-mood');
      window.$(".mood-" + moodIndex).addClass('active-mood');
      const data = self.moods[moodIndex].athkars;
      self.moodMessages = data
    },
    getMoods() {
      const self = this;
      window.$.ajaxSetup({
        headers: {
          'location': 'web'
        }
      });
      window.$.getJSON('https://api.athkariapp.com/api/v1/moods', function (data) {
        self.moods = data;
        self.printMoods(0);
      });
    },
  },
  mounted() {
    this.getMoods()
  },
}
</script>

<style scoped>

</style>
