<template>
  <div>
    <!-- Start Page Title Area -->
    <section class="page-title-banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>{{ title }}</h2>
          </div>
        </div>
      </div>

      <div class="shape1"><img :src="`${publicPath}assets/img/shape1.png`" alt="img"></div>
      <div class="shape2"><img :src="`${publicPath}assets/img/shape2.png`" alt="img"></div>
      <div class="shape3"><img :src="`${publicPath}assets/img/shape3.png`" alt="img"></div>
      <div class="shape6"><img :src="`${publicPath}assets/img/shape6.png`" alt="img"></div>
      <div class="shape8 rotateme"><img :src="`${publicPath}assets/img/shape8.svg`" alt="shape"></div>
      <div class="shape9"><img :src="`${publicPath}assets/img/shape9.svg`" alt="shape"></div>
    </section>
    <!-- End Page Title Area -->

    <!-- Start Blog Area -->
    <section class="blog-area blog-section ptb-100">
      <div class="container">
        <div class="row zikirMessages">
          <div class="col-lg-12" v-for="(value, name) in zikirDetails" :key="name">
            <div class="single-blog-post">
              <div class="blog-post-content text-center">
                {{value.prfx == null ? "" : value.prfx}}
                <p>{{value.txt}}</p>
                {{value.note == null ? "" : value.note}}
              </div>
              <button :class="value.count == 0 ? 'btn btn-danger text-center btn-block' : 'btn btn-success text-center btn-block'" @click="decreaseNumber(name)">{{ value["count"] }}</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Blog Area -->
  </div>
</template>

<script>
export default {
  name: "ZikirDetail",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      zikirDetails: [],
      id: this.$route.params.id,
      title: this.$route.params.title
    };
  },
  methods: {
    getZikirDetail() {
      const self = this;
      window.$.ajaxSetup({
        headers: {
          'location': 'web'
        }
      });
      window.$.getJSON('https://api.athkariapp.com/api/v1/zikirs/' + self.id, function (data) {
        self.zikirDetails = data;
      });
    },
    decreaseNumber(counter) {
      const self = this;
      if (self.zikirDetails[counter]["count"] > 0) {
        self.zikirDetails[counter]["count"] = self.zikirDetails[counter]["count"] - 1;
      } else {
        self.zikirDetails[counter]["count"] = 0
      }
    },
  },
  mounted() {
    this.getZikirDetail()
  }
}
</script>

<style scoped>

</style>
